import React, { useMemo } from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { Carousel } from "src/components/carousel"
import { Browser } from "src/components/device"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"
import { VideoPlayer } from "src/components/video-player"

import {
  HeadlineSuperhero,
  HeadlineSecondary,
  Paragraph,
  HorizontalRuler,
  Cell,
  Text,
  Anchor,
  Container,
  Block,
  Clipped,
} from "src/components/primitives"

import {
  HeaderSuperhero,
  HeaderSecondary,
  HomeFeaturedCaseStudy,
  LogosGrid,
  Accordion,
} from "src/components/layouts"

import { useDarkTheme } from "src/context/theme"

import SEO from "src/components/seo"

type DataProps = {
  dataJson: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      intro: string
      our_clients: string
    }
    featured: [
      {
        key: string
        title: string
        subtitle: string
        link: string
      }
    ]
    images: {
      heroreel: any
      spotify_minireel: any
      hilton_minireel: any
      delta_minireel: any
      office_book_slides_01: any
      office_book_slides_02: any
      office_book_slides_03: any
      office_book_slides_04: any
      powerof_slides_01: any
      powerof_slides_02: any
      powerof_slides_03: any
      powerof_slides_04: any
      bmgf_slides_01: any
      bmgf_slides_02: any
      bmgf_slides_03: any
      bmgf_slides_04: any
      bmgf_slides_05: any
      bmgf_slides_06: any
      ebay_minireel: any
      lab_minireel: any
      gpp_minireel: any
      logos: any
    }
    videos: {
      heroreel: any
      spotify_minireel: any
      hilton_minireel: any
      delta_minireel: any
      ebay_minireel: any
      gpp_minireel: any
      lab_minireel: any
    }
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, featured, videos, images } = data.dataJson

  useDarkTheme()

  // reduce featured projects by key (graphql returns array)
  const featuredByKey: any = useMemo(() => {
    return featured.reduce((res, cur) => {
      res[cur.key] = cur
      return res
    }, {})
  }, [featured])

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "theme-dark",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <article>
        <Container>
          <HeaderSuperhero>
            <HeadlineSuperhero>{copy.title}</HeadlineSuperhero>
          </HeaderSuperhero>
          <Paragraph>{copy.intro}</Paragraph>
        </Container>

        <Container bleed>
          <Block>
            <VideoLoopResponsive
              ratio="$16/9"
              video={videos.heroreel}
              image={images.heroreel}
            />
          </Block>
        </Container>

        <Container>
          <HorizontalRuler />
        </Container>

        <Container as="section" aria-label="Case Studies">
          <Clipped>
            <h2>Case Studies</h2>
          </Clipped>
          <ul>
          <HomeFeaturedCaseStudy
              title={featuredByKey.bmgf.title}
              subtitle={featuredByKey.bmgf.subtitle}
              link={featuredByKey.bmgf.link}
            >
              <Carousel
                  images={[
                    images.bmgf_slides_01,
                    images.bmgf_slides_02,
                    images.bmgf_slides_03,
                    images.bmgf_slides_04,
                    images.bmgf_slides_05,
                    images.bmgf_slides_06,
                  ]} >
                </Carousel>
            </HomeFeaturedCaseStudy>

            <HomeFeaturedCaseStudy
              title={featuredByKey.hilton.title}
              subtitle={featuredByKey.hilton.subtitle}
              link={featuredByKey.hilton.link}
            >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.hilton_minireel}
                image={images.hilton_minireel}
              />
            </HomeFeaturedCaseStudy>

            <HomeFeaturedCaseStudy
              title={featuredByKey.office.title}
              subtitle={featuredByKey.office.subtitle}
              link={featuredByKey.office.link}
            >
              <Carousel
                  images={[
                    images.office_book_slides_01,
                    images.office_book_slides_02,
                    images.office_book_slides_03,
                    images.office_book_slides_04,
                  ]} >
                </Carousel>
            </HomeFeaturedCaseStudy>

            <HomeFeaturedCaseStudy
              title={featuredByKey.spotify.title}
              subtitle={featuredByKey.spotify.subtitle}
              link={featuredByKey.spotify.link}
            >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.spotify_minireel}
                image={images.spotify_minireel}
              />
            </HomeFeaturedCaseStudy>

            <HomeFeaturedCaseStudy
              title={featuredByKey.powerof.title}
              subtitle={featuredByKey.powerof.subtitle}
              link={featuredByKey.powerof.link}
              >
                <Carousel
                  images={[
                    images.powerof_slides_01,
                    images.powerof_slides_02,
                    images.powerof_slides_03,
                    images.powerof_slides_04,
                  ]} >
                </Carousel>
            </HomeFeaturedCaseStudy>

            <HomeFeaturedCaseStudy
              title={featuredByKey.delta.title}
              subtitle={featuredByKey.delta.subtitle}
              link={featuredByKey.delta.link}
            >
               <VideoLoopResponsive
                ratio="$16/9"
                video={videos.delta_minireel}
                image={images.delta_minireel}
              />
            </HomeFeaturedCaseStudy>

            <HomeFeaturedCaseStudy
              title={featuredByKey.ebay.title}
              subtitle={featuredByKey.ebay.subtitle}
              link={featuredByKey.ebay.link}
            >
               <VideoLoopResponsive
                ratio="$16/9"
                video={videos.ebay_minireel}
                image={images.ebay_minireel}
              />
            </HomeFeaturedCaseStudy>

            <HomeFeaturedCaseStudy
              title={featuredByKey.gpp.title}
              subtitle={featuredByKey.gpp.subtitle}
              link={featuredByKey.gpp.link}
            >
              <Block>
                <Cell size="$large" style={{ backgroundColor: "#FFEC40" }}>
                  <Browser screen="#FFFFFF" translucent >
                    <VideoLoopResponsive
                      ratio="$16/9"
                      video={videos.gpp_minireel}
                      image={images.gpp_minireel}
                    />
                  </Browser>
                </Cell>
              </Block>
            </HomeFeaturedCaseStudy>

            <HomeFeaturedCaseStudy
              title={featuredByKey.lab.title}
              subtitle={featuredByKey.lab.subtitle}
              link={featuredByKey.lab.link}
            >
               <VideoLoopResponsive
                ratio="$16/9"
                video={videos.lab_minireel}
                image={images.lab_minireel}
              />
            </HomeFeaturedCaseStudy>
          </ul>
        </Container>

        <Container as="section" aria-label="Our Clients">
          <Clipped>
            <h2>Our Clients</h2>
          </Clipped>
          <HeaderSecondary>
            <HeadlineSecondary>{copy.our_clients}</HeadlineSecondary>
          </HeaderSecondary>

          <LogosGrid>
            {images.logos.map(logo => (
              <ImgFluid key={logo.src.id} image={logo} />
            ))}
          </LogosGrid>
        </Container>

        <Container id="about" as="section" aria-label="Info">
          <Clipped>
            <h2>Information</h2>
          </Clipped>
          <Accordion id="about" title="About" numeral="01">
            <Text>
              Our talented, experienced team is united by a passion to reimagine
              digital experiences through smart, thoughtful design.
            </Text>
            <Text>
              We help great companies envision the future of their products and
              services, great organizations revitalize their brands, and great
              thinkers tell bold stories that chart new paths forward.
            </Text>
            <Text>
              We are also an incubation lab. We have the confidence to invest in
              ourselves and others on joint ventures that explore big ideas. We
              are unafraid to venture outside our comfort zone in pursuit of
              progress.
            </Text>
            <Text>
              Whatever we do, however we do it, we are driven by curiosity and
              ambition. We know we don’t have all the answers—so we go looking
              for them. We are Pilot.
            </Text>
          </Accordion>

          <Accordion id="careers" title="Careers" numeral="02">
            <Text>
              We do not currently have any open roles.
            </Text>
            {/* hide this anchor link in case we need to add JD links back 
            <Anchor href="/jobs/Pilot_SeniorUXDesigner_JD.pdf" target="_blank">
              Senior UX Designer
            </Anchor>

            <Anchor href="/jobs/Pilot_UXDesignLead_JD.pdf" target="_blank">
              UX Design Lead
            </Anchor>
            */}
          </Accordion>

          <Accordion id="locations" title="Locations" numeral="03">
            <div>
              <Clipped>
                <h3>Seattle</h3>
              </Clipped>
              <Text aria-hidden>SEA</Text>
              <Text>
                1201 10th Ave Seattle
                <br />
                WA 98122
                <br />
                47.6° N / 122.3° W<br />
                +1 425 260 0069
              </Text>
            </div>
            <div>
              <Clipped>
                <h3>London</h3>
              </Clipped>
              <Text aria-hidden>LON</Text>
              <Text>
                125-127 Mare St
                <br />
                London E8 3SJ
                <br />
                51.3° N / 0.33° W<br />
                +44 770 366 8088
                <br />
              </Text>
            </div>
          </Accordion>
        </Container>
      </article>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    dataJson(id: { eq: "home" }) {
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        intro
        our_clients
      }
      featured {
        key
        title
        subtitle
        link
      }
      images {
        heroreel {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        spotify_minireel {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        hilton_minireel {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        delta_minireel {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        office_book_slides_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        office_book_slides_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        office_book_slides_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        office_book_slides_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        powerof_slides_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        powerof_slides_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        powerof_slides_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        powerof_slides_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        bmgf_slides_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        bmgf_slides_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        bmgf_slides_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        bmgf_slides_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        bmgf_slides_05 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        bmgf_slides_06 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        ebay_minireel {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        gpp_minireel {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        lab_minireel {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }

        logos {
          alt
          src {
            id
            childImageSharp {
              ...ThirdColumnImageFragment
            }
          }
        }
      }

      videos {
        heroreel {
          src {
            src_large
            src_medium
            src_small
          }
        }
        spotify_minireel {
          src {
            src_large
            src_medium
            src_small
          }
        }
        hilton_minireel {
          src {
            src_large
            src_medium
            src_small
          }
        }
        delta_minireel {
          src {
            src_large
            src_medium
            src_small
          }
        }
        ebay_minireel {
          src {
            src_large
            src_medium
            src_small
          }
        }
        gpp_minireel {
          src {
            src_large
            src_medium
            src_small
          }
        }
        lab_minireel {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`
